<template>
    <div class="wrapper_main footer-fixed">
        <main class="content">
            <!-- <div class="content-header">
                <div class="headline header-wrapper">
                    <button v-if="isTabComponent" type="button" @click="close" class="btn btn-w-shadow btn-sm btn-back btn-fix">
                            <inline-svg :src="require('@/assets/img/Arrow-prev.svg')" />
                    </button>
                    <div>
                        <h1 class="headline__title">Add a Rule</h1>
                        <p class="headline__text">
                            {{
                                activeTab === "behavior" ? 
                                'Alert me when all the selected conditions are met:' :
                                'Assign a specific rule to the IOT device.'
                            }}
                        </p>
                    </div>
                </div>
            </div> -->

            <div class="head-controls">
                <router-link to="/rules" class="btn btn-w-shadow btn-sm btn-back head-controls__button">
                    <inline-svg :src="require(`@/assets/img/Arrow-prev.svg`)" />
                </router-link>
                <form action="#" class="head-controls__form">
                    <input type="text" class="input head-controls__input" v-model="rule.name" v-if="editName" />
                    <div class="heading-wrapper flex items-center head-controls__input" v-else>
                        <button class="btn btn-transp btn-rename" @click="toggleEditName">
                            <img src="@/assets/img/Pen-Edit.svg" />
                        </button>
                        <h1 class="heading-page-h1">{{rule.name}}</h1>
                    </div>
                    <div class="toggle-check" @click="toggleRuleActive(rule.status)">
                        <input type="checkbox" name="toggle-check" :checked="rule.status=='enabled'" />
                        <span class="toggle-check__body-wrapper">
                            <span class="toggle-check__body">
                                <span class="toggle-check__hendler"></span>
                            </span>
                            <span class="text-check">{{ $translate('enabled') }}</span>
                            <span class="text-not-check">{{ $translate('disabled') }}</span>
                        </span>
                    </div>
                </form>
            </div>

            <div class="tabs-wrapper">
                <ul class="tabs">   
                    <li class="tabs__item" v-for="tab in pageTabsArray" :key="tab.value" :class="tab.class ? tab.class : ''">
                        <a class="nav-tab" @click="switchTab(tab.value)" :class="isActiveTab(tab.value)">
                            {{ tab.title }}
                        </a>
                    </li>
                </ul>
            </div>

            <div class="tab-content">
                <div id="info" class="tab-content__item" :class="isActiveTab('info')">
                    <div class="addition">
                        <div class="headline headline--sm">
                            <h2 class="headline__title">{{ $translate('basic-info') }}</h2>
                            <p class="headline__text">
                                {{ $translate('basic-setting-for-a-rule') }}
                            </p>
                        </div>
                        <div class="form-group form-group--wrapper">
                            <!-- <div class="form-group__row">
                                <div class="form-group__col-5">
                                    <div class="el-form">
                                        <label class="el-form__title">Name</label>
                                        <input type="text" class="input" v-model="rule.name"/>
                                    </div>
                                </div>
                            </div> -->
                            <div class="form-group__row" v-if="!deviceID">
                                <div class="form-group__col-5">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('device') }}</label>
                                        <DropdownAdvanced 
											:options="devicesOptions"
											v-model="deviceIDSelected"
                                            @change="generateParameterObeserved"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
             
                <div id="behavior" class="tab-content__item"  :class="isActiveTab('behavior')">
                    <div class="addition">
                        <div class="headline headline--sm">
                            <h2 class="headline__title">{{ $translate('behavior') }}</h2>
                            <p class="headline__text">
                                {{ $translate('specify-how-your-devices-should-behave-on-triger.') }}
                            </p>
                        </div>
                        <div class="wizard-item">
                            <div class="items-group cards mt-15">
                                <div class="cards__col-6">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('observation-period') }}</label>
                                        <DropdownAdvanced
                                            :options="[
                                                { id: 'one-off', name: 'One-off' },
                                                { id: 'periodic', name: 'Periodic' },
                                            ]"
                                            v-model="rule.behavior.observation_period"
                                        />
                                    </div>
                                </div>

                                <div class="cards__col-6" v-if="rule.behavior.observation_period === 'periodic'">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('temporal-setting') }}</label>
                                        <div class="flex items-center">
                                            <label class="toggle-check">
                                                <input type="checkbox" name="toggle-check" v-model="rule.behavior.temporal_settings"/>
                                                <span class="toggle-check__body-wrapper">
                                                    <span class="toggle-check__body">
                                                        <span class="toggle-check__hendler"></span>
                                                    </span>
                                                    <span class="text-check">{{ $translate('on') }}</span>
                                                    <span class="text-not-check">{{ $translate('off') }}</span>
                                                </span>
                                            </label>
                                            <DropdownAdvanced
                                                :options="[
                                                    { id: 'daily', name: 'Daily' },
                                                    { id: 'weekly', name: 'Weekly' },
                                                    { id: 'monthly', name: 'Monthly' },
                                                ]"
                                                v-model="rule.behavior.temporal_settings"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="items-group cards mt-15" v-if="rule.behavior.temporal_settings">
                                <div class="cards__col-6" v-if="rule.behavior.temporal_settings === 'weekly'">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('day-of-observation') }}</label>
                                        <DropdownAdvanced
                                            :options="dayOfTheWeekOptions"
                                            v-model="rule.behavior.day_of_the_week"
                                        />
                                    </div>
                                </div>
                                <div class="cards__col-6" v-if="rule.behavior.temporal_settings === 'monthly'">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('day-of-observation') }}</label>
                                        <DropdownAdvanced
                                            :options="dayOfTheMonthOptions"
                                            v-model="rule.behavior.day_of_the_month"
                                        />
                                    </div>
                                </div>
                                <div class="cards__col-6 flex">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('time-of-observation-from') }}</label>
                                        <TimePicker                                        
                                            v-model="rule.behavior.time_of_observation_from"
                                        />
                                    </div>
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('time-of-observation-to') }}</label>
                                        <TimePicker                                        
                                            v-model="rule.behavior.time_of_observation_to"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="items-group cards mt-15">
                                <div class="cards__col-6">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('parameter') }}</label>
                                        <DropdownAdvanced
                                            :options="parameterObeservedOptions"
                                            v-model="rule.behavior.parameter"
                                        />
                                    </div>
                                </div>
                                <div class="cards__col-6">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('behaviour') }}</label>
                                        <DropdownAdvanced
                                            :options="[
                                                { id: 'single', name: 'Single value' },
                                                ...(rule.behavior.temporal_settings ? [
                                                { id: 'min', name: 'Min value' },
                                                { id: 'max', name: 'Max value' },
                                                { id: 'average', name: 'Average' }] : [])
                                            ]"
                                            v-model="rule.behavior.behavior"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="items-group cards mt-15">
                                <div class="cards__col-6">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('operator') }}</label>
                                        <DropdownAdvanced
											:options="operatorOptions"
											v-model="rule.behavior.operator"
                                        />
                                    </div>
                                </div>
                                <div class="cards__col-6">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('value') }}</label>
                                        <input type="text" class="input" v-model="rule.behavior.value">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="alert" class="tab-content__item" :class="isActiveTab('alert')">
                    <div class="addition">
                        <div class="headline headline--sm">
                            <h2 class="headline__title">{{ $translate('alert-actions') }}</h2>
                            <p class="headline__text">
                                {{ $translate('select-what-action-your-rule-should-take') }}
                            </p>
                        </div>
                        <ul class="editable-list">
                            <!-- sms/email -->
                            <li class="editable-list__item" v-if="rule.alert_actions.sms_email">
                                <div class="data-row">
                                    <ul class="data-row__list">
                                        <li class="data-row__item">
                                            <span class="data-row__icon data-row__icon--primary">
                                                <inline-svg :src="require(`@/assets/img/ico-mail.svg`)"/>
                                            </span>
                                            {{ $translate('email') }}
                                        </li>
                                        <li class="data-row__item">
                                            {{rule.alert_actions.display_name}}
                                        </li>
                                    </ul>
                                    <div class="data-row__controls">
                                        <button class="btn btn-icon" @click="alertPopupOpen('sms_email','edit')">
                                            <inline-svg :src="require(`@/assets/img/ico-gear.svg`)"/>    
                                        </button>
                                        <button class="btn btn-icon red-color" @click="alertClear('sms_email')">
                                            <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)"/>
                                        </button>
                                    </div>
                                </div>
                            </li>
                            <!-- dashboard -->
                            <li class="editable-list__item" v-if="rule.alert_actions.dashboard">

                                <div class="data-row">
                                    <ul class="data-row__list">
                                        <li class="data-row__item">
                                            <span class="data-row__icon data-row__icon--primary">
                                                <inline-svg :src="require(`@/assets/img/ico-mail.svg`)"/>
                                            </span>
                                            {{ $translate('dashboard') }}
                                        </li>
                                        <li class="data-row__item">
                                            {{rule.alert_actions.display_name}}
                                        </li>
                                    </ul>
                                    <div class="data-row__controls">
                                        <button class="btn btn-icon" @click="alertPopupOpen('dashboard','edit')">
                                            <inline-svg :src="require(`@/assets/img/ico-gear.svg`)"/>    
                                        </button>
                                        <button class="btn btn-icon red-color" @click="alertClear('dashboard')">
                                            <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)"/>
                                        </button>
                                    </div>
                                </div>
                            </li>
                            <!-- m2m -->
                            <li class="editable-list__item" v-if="rule.alert_actions.m2m">
                                <div class="data-row">
                                    <ul class="data-row__list">
                                        <li class="data-row__item">
                                            <span class="data-row__icon data-row__icon--primary">
                                                <inline-svg :src="require(`@/assets/img/ico-mail.svg`)"/>
                                            </span>
                                            {{ $translate('machine-to-machine') }}
                                        </li>
                                        <li class="data-row__item">
                                            {{rule.alert_actions.display_name}}
                                        </li>
                                    </ul>
                                    <div class="data-row__controls">
                                        <button class="btn btn-icon" @click="alertPopupOpen('m2m','edit')">
                                            <inline-svg :src="require(`@/assets/img/ico-gear.svg`)"/>    
                                        </button>
                                        <button class="btn btn-icon red-color" @click="alertClear('m2m')">
                                            <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)"/>
                                        </button>
                                    </div>
                                </div>
                            </li>
                            <!-- webhook -->
                            <li class="editable-list__item" v-if="rule.alert_actions.webhook">
                                <div class="data-row">
                                    <ul class="data-row__list">
                                        <li class="data-row__item">
                                            <span class="data-row__icon data-row__icon--primary">
                                                <inline-svg :src="require(`@/assets/img/ico-mail.svg`)"/>
                                            </span>
                                            {{ $translate('webhook') }}
                                        </li>
                                        <li class="data-row__item">
                                            {{rule.alert_actions.display_name}}
                                        </li>
                                    </ul>
                                    <div class="data-row__controls">
                                        <button class="btn btn-icon" @click="alertPopupOpen('webhook','edit')">
                                            <inline-svg :src="require(`@/assets/img/ico-gear.svg`)"/>    
                                        </button>
                                        <button class="btn btn-icon red-color" @click="alertClear('webhook')">
                                            <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)"/>
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="addition__row">
                            <div class="addition__col">
                                <WhitePlusButton @click="alertPopupOpen('sms_email')" >{{ $translate('sms-/-email') }}</WhitePlusButton>
                            </div>
                            <div class="addition__col">
                                <WhitePlusButton @click="alertPopupOpen('dashboard')" >{{ $translate('dashboard') }}</WhitePlusButton>
                            </div>
                            <!-- <div class="addition__col">
                                <WhitePlusButton @click="alertPopupOpen('m2m')" >{{ $translate('machine-to-machine') }}</WhitePlusButton>
                            </div> -->
                            <!-- <div class="addition__col">
                                <WhitePlusButton @click="alertPopupOpen('webhook')" >{{ $translate('webhook') }}</WhitePlusButton>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </main>

        <FooterTabsControl
            v-if="isTabComponent"
            :previous="true"
            :confirmName="$translate('save')"
            @cancelAction="close"
            @confirm="updateRule"
            @swipeTab="swipeTab"
            :pageTabs="pageTabs"
            :activeTab="activeTab"
            :processing="processing"
        />
        <!-- before translate confirmName="Save" -->
        <FooterTabsControl
            v-else
            :previous="true"
            cancelRoute="/rules"
            :confirmName="$translate('save')"
            @confirm="updateRule"
            @swipeTab="swipeTab"
            :pageTabs="pageTabs"
            :activeTab="activeTab"
            :processing="processing"
        />
        <!-- before translate confirmName="Save" -->
		<AddDevice
			v-if="addDevicesPopupInfo.show"
			:devices="addDevicesPopupInfo.devices"
			@close="addDeviceClose"
			@submit="addDeviceSubmit"
			:title="$translate('assign-device-to-group')"
		/>
        <!-- before translate title="Assign Device(s) to group" -->
        <AlertSMS 
            v-if="activeAlertModal.name==='sms_email'"
            :mode="activeAlertModal.mode" 
            @close="alertPopupClose('sms_email')" 
            @submit="alertPopupSubmit"
            :alertsData="rule.alert_actions">
        </AlertSMS>
        
        <AlertDashboard 
            v-if="activeAlertModal.name==='dashboard'"
            :mode="activeAlertModal.mode" 
            @close="alertPopupClose('dashboard')" 
            @submit="alertPopupSubmit"
            :alertsData="rule.alert_actions">
        </AlertDashboard>
        <AlertM2M 
            v-if="activeAlertModal.name==='m2m'"
            :mode="activeAlertModal.mode" 
            @close="alertPopupClose('m2m')" 
            @submit="alertPopupSubmit"
            :alertsData="rule.alert_actions"
            :devices="devicesForAlert">
        </AlertM2M>
        <AlertWebhook 
            v-if="activeAlertModal.name==='webhook'"
            :mode="activeAlertModal.mode" 
            @close="alertPopupClose('webhook')" 
            @submit="alertPopupSubmit"
            :alertsData="rule.alert_actions">
        </AlertWebhook>
    </div>
</template>

<script>
import { API } from 'aws-amplify';
import { 
    updateRules, 
} from "@/graphql/mutations";
import {
    getRules,
} from "@/graphql/queries";


import Base from "@/components/base";
import Popups from '@/components/popups';
import Buttons from '@/components/buttons';

import { 
	operators 
} from '@/config/rules'
import { mixTabs,mixProcessing,mixArrayFields } from "@/mixins";
import ItemsRows from "@/components/itemsRows";

export default {
    name: "RulesEdit",
    props: ["isTabComponent", "deviceID", "id"],

    components: {
        ...Base,
		...Popups,
        ...Buttons,
        ...ItemsRows
    },
	mixins:[mixTabs,mixProcessing,mixArrayFields],
    data() {
        return {
			addDevicesPopup: false,
			alertAction: false,
            arrayFields:{
				devices:[]
			},
            pageTabs: ['info', 'behavior', 'alert'],
            pageTabsArray: [
                { title: 'Info', value: 'info' },
                // { title: 'Selected Devices', value: 'devices' },
                { title: 'Behaviour', value: 'behavior', class: 'tutorial_tooltip_1 tutorial_tooltip_top_center tutorial_tooltip_fs14' },
                { title: 'Alert Actions', value: 'alert' },
            ],
            activeAlertModal:{},

			rule: {
				name: null,
				status: 'enabled',
				companyID: null,
				behavior: {
					temporal_settings: null,
					parameter: null,
					aggregation: null,
					operator: null,
					value: null,
					observation_period: null,
                    behavior: null,
                    day_of_the_month: null,
                    day_of_the_week: null,
                    time_of_observation_from: "00:00",
                    time_of_observation_to: "00:00",
				},
				alert_actions: {
					sms_email: null,
					dashboard: null,
					m2m: null,
					webhook: null,
					display_name: null,
					note: null,
					email: null,
					tel: null,
					deviceID: null,
					device_action: null,
					callback_url: null
				}
			},
            deviceIDSelected: null,
            parameterObeservedOptions: [],
            backup: null,
            editName: false,
            currentRuleDevice: null,
        };
    },
	computed: {
        userData() {
			return this.$store.state.userData || {}
		},
		companyData() {
			return this.$store.state.companyData
		},
		devices() {
			return this.$store.state.devices
		},
		devicesOptions() {
			return [...this.$store.state.devices].map(d => ({ name: d.name, id: d.id }))
		},

		telemetryOptions() {
			return this.fieldValues('devices').map(d => ({ name: d.category, id: d.id }))
		},
		// aggregationOptions() {
		// 	return [{ id: 'average', name: 'Average' }]
		// },
		operatorOptions() {
			return Object.entries(operators).map(o => ({ id: o[0], name: o[1] }))
		}, 
        devicesForAlert(){            
            return this.devices.map(d=>{
                return {id:d.id,name:d.name}
            }).filter(d => {
                return d.id !== this.deviceIDSelected;
            })
        },
        observedParameter(){
            return this.devices.filter(item => item.id === this.deviceIDSelected).map(d => ({ name: d.category, id: d.id }));
        },
        dayOfTheMonthOptions(){
            let arrayReturn = [];
            for (let index = 1; index < 32; index++) {
                arrayReturn.push({ id: index, name: index })
            }
            return arrayReturn;
        },
        dayOfTheWeekOptions(){
            return [
                { id: 'mon', name: 'Monday' },
                { id: 'tue', name: 'Tuesday' },
                { id: 'wed', name: 'Wednesday' },
                { id: 'thu', name: 'Thursday' },
                { id: 'fri', name: 'Friday' },
                { id: 'sat', name: 'Saturday' },
                { id: 'sun', name: 'Sunday' },
            ]
        },
	},
    methods: {
        toggleEditName(){
            this.editName=!this.editName
        }, 
        async generateParameterObeserved(e){
            let findedDevice = this.deviceID || this.currentRuleDevice || this.id ? this.devices.find(item => item.id === (this.deviceID || this.currentRuleDevice || this.id)) : e ? this.devices.find(item => item.id === e.target.value.id) : null;

            // this.rule.behavior.parameter = null;

            if(findedDevice && findedDevice.id){
                this.parameterObeservedOptions = [];

                if(findedDevice.category === 'carbon-dioxide' || findedDevice.category === 'phosphine') {
                    let deviceDataFromApi = await this.$provider.centaurAPI.getRealDeviceData(findedDevice.config.device_id);

                    for (let index = 0; index < deviceDataFromApi.length; index++) {
                        let devCatsFinded = devCats.find(dItem => dItem.centaur_type_id === deviceDataFromApi[index].type);
                        
                        if(devCatsFinded && !this.parameterObeservedOptions.map(item => item.id).includes(devCatsFinded.id)){
                            this.parameterObeservedOptions.push({
                                name: devCatsFinded.name,
                                id: devCatsFinded.id
                            });
                        }
                    }
                } else {
                    let parameters = [];

                    if(findedDevice.category === 'temperature' || findedDevice.category === 'temperature-humidity' || findedDevice.category === 'weather-station-xsense'){
                        parameters = [
                            ['Temperature', 'temperature']
                        ];
					}

					if(findedDevice.category === 'leaf-moisture'){
                        parameters = [
                            ['Leaf moisture', 'leaf-moisture']
                        ];
					}

					if(findedDevice.category === 'soil-moisture'){
                        parameters = [
                            ['Soil moisture', 'soil-moisture']
                        ];
					}

					if(findedDevice.category === 'soil-moisture-teros-10'){
                        parameters = [
                            ['Temperature', 'temperature'],
                            ['Air humidity', 'air-humidity'],
                        ];
					}

                    if(findedDevice.category === 'temperature-humidity'){
                        parameters = [
                            ['Dew point temp', 'dew-point-temp'],
                            ['Air humidity', 'air-humidity']
                        ];
                    }

                    if(findedDevice.category === 'weather-station-xsense'){
                        parameters = [
                            ['Temperature', 'dew-point-temp'],
                            ['Air humidity', 'air-humidity'],
                            ['Rain Meter', 'rain-meter'],
                            ['Average wind speed', 'average-wind-speed'],
                            ['Max wind speed', 'max-wind-speed'],
                            ['Solar Radiation', 'solar-radiation']
                        ];
                    }

                    for (let index = 0; index < parameters.length; index++) {
                        this.parameterObeservedOptions.push({
                            name: parameters[index][0],
                            id: parameters[index][1]
                        });
                    }
                }
            }
        },
		alertPopupOpen(name,mode="add") {
			this.activeAlertModal = {name,mode};
		},
		alertPopupClose() {
            this.activeAlertModal = {}
        },
        alertPopupSubmit(alert,alertData){
            console.log(alert,alertData);
            switch (alert) {
                case "sms_email":
                    this.rule.alert_actions.sms_email=true
                    this.rule.alert_actions.display_name=alertData.display_name
                    this.rule.alert_actions.note=alertData.note

                    this.rule.alert_actions.mode=alertData.mode
                    this.rule.alert_actions.email=alertData.email
                    this.rule.alert_actions.tel=alertData.tel
                    break;
                case "dashboard":
                    this.rule.alert_actions.dashboard=true
                    this.rule.alert_actions.note=alertData.note
                    
                    this.rule.alert_actions.display_name=alertData.display_name
                    break;
                case "m2m":
                    this.rule.alert_actions.m2m=true
                    this.rule.alert_actions.display_name=alertData.display_name

                    
                    this.rule.alert_actions.deviceID = alertData.deviceID
					this.rule.alert_actions.device_action = alertData.device_action
                    break;
                case "webhook":
                    this.rule.alert_actions.webhook=true

                    this.rule.alert_actions.display_name=alertData.display_name
                    this.rule.alert_actions.callback_url= alertData.callback_url
                    break;
                default:
                    break;
            }
            this.alertPopupClose()
        },  
        alertClear(alert){
            switch (alert) {
                case "sms_email":
                    this.rule.alert_actions.sms_email=false
                    this.rule.alert_actions.mode=null
                    this.rule.alert_actions.email=null
                    this.rule.alert_actions.tel=null
                    break;
                case "dashboard":
                    this.rule.alert_actions.dashboard=false
                    break;
                case "m2m":
                    this.rule.alert_actions.m2m=false
                    this.deviceIDSelected = null
					this.device_action = null
                    
                    break;
                case "webhook":
                    this.rule.alert_actions.webhook=false
                    this.rule.alert_actions.callback_url=null
                    break;
                default:
                    break;
            }
            if( !(this.rule.alert_actions.sms_email || this.rule.alert_actions.dashboard || this.rule.alert_actions.m2m || this.rule.alert_actions.webhook) ){
                this.rule.alert_actions.display_name=null
                this.rule.alert_actions.note=null
            }
        },  
	
	
		async updateRule() {
            this.processing = true;
			let input = {
				...this.rule,
                deviceID: this.deviceIDSelected,
				companyID: this.companyData.id,
                userID: this.userData.id,
			}

            delete input.__typename;
            delete input.behavior.__typename;
            delete input.alert_actions.__typename;

            const rule = await API.graphql({
                query: updateRules,
                variables: {
                    input: {
                        id: this.id,
                        ...input,
                    },
                },
            }).then((res) => res.data.updateRules);
            
            await this.getRuleInfo()

            this.refreshData()

            this.processing = false;
			this.close();
		},
        async refreshData() {
            await this.$store.dispatch("GET_CATEGORIES");
            await this.$store.dispatch("GET_PRODUCTS");
            await this.$store.dispatch("GET_GROUPS"); 
            await this.$store.dispatch("GET_DEVICES");
        },
        close(){
            if(this.isTabComponent){
                this.$emit("close");
            } else {
                this.$router.push({ path: '/rules' });
            }
        },
        async getRuleInfo() {
            const rule = await API.graphql({
                query: getRules,
                variables: {
                    id: this.id,
                },
            }).then((res) => res.data.getRules);
        
            this.setRuleInfo(rule)
            this.backup = JSON.parse(JSON.stringify(rule));
        },
        setRuleInfo(valueRule){
            console.log('newRule:', valueRule);
            let newRule = JSON.parse(JSON.stringify(valueRule));

            if(newRule.behavior.time_of_observation_from === "undefined:undefined"){
                newRule.behavior.time_of_observation_from = null;
            }
            if(newRule.behavior.time_of_observation_to === "undefined:undefined"){
                newRule.behavior.time_of_observation_to = null;
            }

            if(newRule?.deviceID){
                this.currentRuleDevice = newRule?.deviceID
            }

            this.deviceIDSelected = newRule.deviceID;

            delete newRule.device;
            delete newRule.createdAt;
            delete newRule.company;
            delete newRule.updatedAt;
            delete newRule.user;
            delete newRule.deviceID;
            // let devices = this.transformFieldToId(newRule.devices.items,'deviceID')
            // this.setFieldItems('devices',devices)
            this.rule = JSON.parse(JSON.stringify(newRule))
        },
        restoreFromBackup() {
            this.setRuleInfo(this.backup)
        },
        async toggleRuleActive(status){        
            this.rule.status = (status === "enabled" ? "disabled" : "enabled");
        }
    },
    async created() {
        await this.getRuleInfo();
        setTimeout(async() => {
           await this.generateParameterObeserved(); 
        }, 1000);
    },
};
</script>

<style scoped>
span.device_cat_title {
	text-transform: capitalize;
	margin-left: 10px;
}
.tab-content__item {
    padding-bottom: 300px;
}
</style>
